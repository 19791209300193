import React from "react";
import { Container } from "reactstrap";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';

import DataTable from '../components/DataTable';
import Loading from "../components/Loading";
import { getConfig } from "../config";
import agent from '../services/agent';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Item,ColorButton } from '../components/Common'; 


export const InvitationComponent = () => {
  const { user } = useAuth0();
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();

  const [age, setAge] = React.useState('');

  const [value, setValue] = React.useState('1');

  const [state, setState] = React.useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [invitation, setInvitation] = React.useState({
    firstname : "Sravathi",
    company:"Onyeg",
    role:"CMO",
    to: "sravanthi.paipuri@gmail.com",
    testtime:"30 Min",
    from: user.email,
    code: Math.floor((Math.random() * 10000) + 1),
    invitationmail:10,
    device:10,
    template:0
  });

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    console.log(event.target.name)
    //setAge(event.target.value);
    //invitation[event.target.name] = event.target.value
    console.log(invitation)
    setInvitation({...invitation,
      [event.target.name]:event.target.value
    });
  };

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
      console.log(invitation);

      let template = "Dear <b>"+invitation.firstname+"</b>,\n\n"

        + "Thank you for your application to the <b>"+invitation.role+"</b> role at <b>"+invitation.company+"</b>.\n\n"
        + "We would like to invite you to take test for the role .\n"
        + "<a href='https://weevaluate.online/test'>Test Link</a> Please login with same mail Id .Please enter the code <b>"+invitation.code+"</b> .\n"
        + "The test will last <b>"+invitation.testtime+"</b> in total."
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          body: template,
          company: invitation.company,
          to: invitation.to,
          from: invitation.from
        })
      };
      const response = await fetch('https://weevaluate.online/invitation.php', requestOptions);
      const data = await response.json();
      console.log("Invitation Data", data)

    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };
  return (
    <Container className="mb-5">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="First Name" variant="outlined" value={invitation.firstname}  name='firstname' onChange={handleChange}/></Item>
          </Grid>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="Last Name" variant="outlined" value={invitation.lastname} name='lastname' onChange={handleChange}/></Item>
          </Grid>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="Email" variant="outlined" value={invitation.to} name='to' onChange={handleChange} /></Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.template}
                  label="Template"
                  name="template"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Entrelevel-1</MenuItem>
                  <MenuItem value={1}>Entrelevel-2</MenuItem>
                  <MenuItem value={2}>Entrelevel-3</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Invitation Mail Formates</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.invitationmail}
                  label="Invitation Mail Formates"
                  name="invitationmail"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Formate 1</MenuItem>
                  <MenuItem value={1}>Formate 2</MenuItem>
                  
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Camera and Mic</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.device}
                  label="Invitation Mail Formates"
                  name="device"
                  onChange={handleChange}
                >
                  <MenuItem value={30}>None</MenuItem>
                  <MenuItem value={10}>Need Camera</MenuItem>
                  <MenuItem value={20}>Need Mic</MenuItem>
                  <MenuItem value={30}>Need Camera and Mic</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item><TextareaAutosize
              aria-label="empty textarea"
              placeholder="Select Invitation mail formate"
              minRows={8}
              style={{ width: '100%' }}
              name="template"
              value={invitation.template}
              onChange={handleChange}
            /></Item>
          </Grid>
          <Grid item xs={12} style={{ height: '70px' }}>

            <Stack spacing={1} direction="row" style={{ float: "right" }}>
            <Button
      variant="contained"
      component="label"
    >
      Upload Resume
      <input
        type="file"
        hidden
        name="uploadFile"
      />
    </Button>
              <ColorButton variant="contained">Bulk Invite</ColorButton>
              <Button variant="contained" onClick={() => callApi()}>Send Invitation</Button>
              <Button variant="contained">Cancel</Button>


            </Stack>

          </Grid>
        </Grid>
        <Grid item xs={12} > <DataTable /></Grid>
      </Box>
    </Container>
  );
};

export default withAuthenticationRequired(InvitationComponent, {
  onRedirecting: () => <Loading />,
});

import React from "react";
import {Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "../views/Home";
import Profile from "../views/Profile";
import ExternalApi from "../views/ExternalApi";
import Questions from "../views/Questions";
import Template from "../views/Template"
import { useAuth0 } from "@auth0/auth0-react";
import { getRole } from "../redux/selectors";

// styles
import "../App.css";

// fontawesome
import initFontAwesome from "../utils/initFontAwesome";
import Dashboard from "../views/Dashboard";
import Invitation from "../views/Invitation";
import Test from "../views/Test";
import commonJSutils from "../utils/common";

initFontAwesome();
function compareRole(dbRole,role){
  return dbRole === role ;
}
const AppRouter = ({ role }) => {
  const {  user,isAuthenticated} = useAuth0();
   return (
   
        <React.Fragment>
          <Switch>
            {!isAuthenticated && (<Route path="/" exact component={Home} />)}
            {isAuthenticated && !commonJSutils.isEmpty(user["http://localhost:3000/roles"]) && role==="hiring" && (<Route path="/" component={Profile} />)}
            {(isAuthenticated && commonJSutils.isExisted(user["http://localhost:3000/roles"], 'employer')) && (<Route path="/" exact component={Dashboard} />)}
             {(isAuthenticated && commonJSutils.isExisted(user["http://localhost:3000/roles"], 'employer')) && (<Route path="/dashboard" component={Dashboard} />)}
             {(isAuthenticated && compareRole(user["http://localhost:3000/roles"][0], 'employer')) && (            <Route path="/questions" component={Questions} />)}
             {(isAuthenticated && compareRole(user["http://localhost:3000/roles"][0], 'employer')) && (<Route path="/template" component={Template} />)}
             {(isAuthenticated && compareRole(user["http://localhost:3000/roles"][0], 'employer')) && (<Route path="/invitation" component={Invitation} />)}
             {isAuthenticated && !commonJSutils.isEmpty(user["http://localhost:3000/roles"]) && role==="candidate" && (<Route path="/" component={Test} />)}
             {isAuthenticated && (<Route path="/profile" component={Profile} />)}
            
          </Switch>
          </React.Fragment>
       
  );
};
const mapStateToProps = state => {
 
  const role=getRole(state)
  return { role };

};
export default connect(mapStateToProps)(AppRouter);

import * as React from 'react';
import { Container } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PieChart, Pie, Sector, Cell,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CustomTooltip, Item } from '../components/Common'; 
import Loading from "../components/Loading";

const data = [
   
    {
      name: 'May',
      invitations: 30,
      evaluated: 13,
      
    },
    {
      name: 'Jun',
      invitations: 20,
      evaluated: 24,
      
    },
    {
      name: 'Jul',
      invitations: 80,
      evaluated: 8,
     
    },
    {
      name: 'Aug',
      invitations: 18,
      evaluated: 48,
      
    },
    {
      name: 'Sep',
      invitations: 23,
      evaluated: 38,
     
    }, {
      name: 'Oct',
      invitations: 40,
      evaluated: 44,
     
    },
    
  ];
  const piedata = [
    { name: 'Invitations', value: 400 },
    { name: 'Test Complated', value: 300 },
    { name: 'Passed', value: 300 },
    { name: 'Pending', value: 200 },

  ];
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#8884d8'];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
 function BasicCard(prop) {
    return (
      <Card sx={{ minWidth: 275 }} style={{background:'white'}}>
        <CardContent>
         
          <Typography variant="h5" component="div">
          {prop.name}
          </Typography>
          <Typography sx={{ mb: 3.5,float:'right',fontSize: 24  }} color={COLORS[0]}>
          {prop.value}
          </Typography>
          <Typography variant="body2">
            
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    );
  } 
export const DashboardComponent = () => {
 
      return (
    <Container >
     <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
      <Grid item xs={6} >
         <Item style={{height:'350px'}}><ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="invitations" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="evaluated" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer></Item>
        </Grid>
        <Grid item xs={6}>
          <Item style={{height:'350px'}}><ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={piedata}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
         <Legend />
        </PieChart>
      </ResponsiveContainer></Item>
        </Grid>
        <Grid item xs={4}>
          <BasicCard name="Invitations" bgcolor={COLORS[2]} value="20/40"></BasicCard>
        </Grid>
        <Grid item xs={4}>
        <BasicCard name="Questions" bgcolor={COLORS[2]} value="60"></BasicCard>
        </Grid>
        <Grid item xs={4}>
        <BasicCard name="Evaluated" bgcolor={COLORS[2]} value="18/20"></BasicCard>
        </Grid>
        <Grid item xs={4}>
        <BasicCard name="Sold Questions" bgcolor={COLORS[2]} value="20"></BasicCard>
        </Grid>
        <Grid item xs={4}>
        <BasicCard name="Spent Amount" bgcolor={COLORS[2]} value="5000Rs"></BasicCard>
        </Grid>
        <Grid item xs={4}>
        <BasicCard name="Subscription" bgcolor={COLORS[2]} value="Expires on Apr 2023"></BasicCard>
        </Grid>
       
      </Grid>
    </Box>
    </Container>
  );
};

export default withAuthenticationRequired(DashboardComponent, {
  onRedirecting: () => <Loading />,
});

import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, User, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Item } from "../components/Common";
import agent from "../services/agent";

export const ProfileComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [state, setState] = React.useState({
    company: "",
    domain: "",
    mail: "contact",
    showSendOTP: true,
    showOTPVerif: false
  })
  const [profile, setProfile] = React.useState({
    status: "open",
    plan: "Free",
    company: "Onyeg",
    companymail: "badri@onyeg.com",
    role: "employer",
    displayname: "Badri",
  })
  let validFormFilds = {
    company: true,
    domain: true,
  }
  const [validForm, setValidForm] = React.useState(validFormFilds)
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  React.useEffect( () => {
    const createProfile = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
     // const res = await agent.Profile.register(user,profile.role)
    //  console.log(res)
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  }
  createProfile()
  }, [User]);
  const sendOTPVerification = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
    //  const res = await agent.Profile.sendMailVerification(profile)
     // console.log(res)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: "badri@onyeg.com",
          user: user.email,
          company:"ONYEG",
        })
      };

      const response = await fetch('https://weevaluate.online/emailverification.php', requestOptions);
      const data = await response.json();
      console.log("Invitation Data", data)
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  //  setState({ ...state, showSendOTP: false, showOTPVerif: true })
  }
  const verifyOTP = () => {
    setState({ ...state, showOTPVerif: false })
    window.location.reload();
  }
  const validDomain = new RegExp(
    '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$'
  );
  const validName = new RegExp(
    '^[a-zA-Z0-9 ]*$'
  );
  const handleProfileChange = (event) => {
    console.log(event.target.ariaInvalid)

    if (validFormFilds.hasOwnProperty(event.target.name)) {
      //validFormFilds[event.target.name]= event.target.ariaInvalid
      setValidForm({
        ...validForm,
        [event.target.name]: event.target.ariaInvalid
      })
    }
    setState({
      ...state,
      [event.target.name]: event.target.value.trim()
    });

    //console.log(validFormFilds)
  };


  return (
    <Container className="mb-5">

      { /*<Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
  </Row>*/}
      <Box sx={{ flexGrow: 1 }}>
        <center>{state.showSendOTP && (<Grid container item xs={8} spacing={2}>
          <Grid item xs={12}>
            Company mail verification is required to start
          </Grid>
          <Grid item xs={12}>
            <Item><TextField fullWidth id="outlined-basic" label="Company Name" name="company" variant="outlined"
              InputProps={{
                error: state.company.trim() === "" || !validName.test(state.company)
              }}
              onChange={handleProfileChange}

            /></Item>
          </Grid>
          <Grid item xs={4}>
            <Item> <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" >Company Mail Id</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="mail"
                label="Company Mail Id"
                value={state.mail}
                onChange={handleProfileChange}
              >
                <MenuItem value={"contact"}>contact</MenuItem>
                <MenuItem value={"hiring"}>hiring</MenuItem>
                <MenuItem value={"hr"}>hr</MenuItem>
                <MenuItem value={"support"}>support</MenuItem>
                <MenuItem value={"info"}>info</MenuItem>
              </Select>
            </FormControl></Item>
          </Grid>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="Domain" variant="outlined" name="domain"
              InputProps={{
                startAdornment: <InputAdornment position="start">@</InputAdornment>,
                error: !validDomain.test(state.domain)
              }}
              onChange={handleProfileChange}
            /></Item>
          </Grid>
          <Grid item xs={3} sx={{ m: 2 }}>
            <Button variant="contained" onClick={sendOTPVerification} >Send OTP</Button>
          </Grid>
        </Grid>
        )}
          {state.showOTPVerif && (<Grid container xs={6} spacing={2}>
            <Grid item xs={12}>
              OTP sent to selected company mail address
            </Grid>

            <Grid item xs={8}>
              <Item><TextField fullWidth id="outlined-basic" label="OTP" variant="outlined" name="code" /></Item>
            </Grid>
            <Grid item xs={3} sx={{ m: 2 }}>
              <Button variant="contained" onClick={verifyOTP}>Verify OTP</Button>
            </Grid>
          </Grid>
          )}
        </center>
      </Box>
      {/* <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>*/}
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});

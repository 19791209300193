import * as React from 'react';
import {Router } from 'react-router-dom';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { contributorListItems, mainListItems, secondaryListItems } from './listItems';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import history from "./utils/history";
import AppRouter from './components/AppRouter';
import Loading from './components/Loading';
import commonJSutils from './utils/common';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open,isauthenticated }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((isauthenticated==="true" && open) && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();
function preventDefault(event) {
  event.preventDefault();
  
}
function DashboardContent() {
   
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
 
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading, error
  } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
    if (error) {
        return <div>Oops... {error.message}</div>;
      }
    
      if (isLoading) {
        return <Loading />;
      }
  const isNavBarRequired = () =>{
    console.log(user)
   if(!user) return false
    if(!isAuthenticated)  return false
    if (!commonJSutils.isEmpty(user["http://localhost:3000/roles"])) return false
    return true
  } 
  console.log(isNavBarRequired())
  return (
    <ThemeProvider theme={mdTheme}>
        <Router history={history}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} isauthenticated={isNavBarRequired(user)?"true":"false"} >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
           {isAuthenticated && user['http://localhost:3000/roles'][0] && (   <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>)}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              WeEvaluate
            </Typography>
            {isAuthenticated && ( <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{user.name}</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu} to="/profile">
                <IconButton  sx={{ p: 0 }}>
                <Typography textAlign="center">Profile</Typography>
              </IconButton>
                 
                </MenuItem>
                <MenuItem  onClick={() => logoutWithRedirect()}>
                <IconButton  sx={{ p: 0 }}>
                <Typography textAlign="center">Logout</Typography>
              </IconButton>
                  
                </MenuItem>
             
            </Menu>
          </Box>)}
           
          {isAuthenticated && user['http://localhost:3000/roles'][0] &&(  <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>)}
          </Toolbar>
        </AppBar>
        {isAuthenticated && user['http://localhost:3000/roles'][0] && (<Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />

            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
         
            {user['http://localhost:3000/roles'][0]=='employer' && mainListItems}
            {user['http://localhost:3000/roles'][0]=='contributer' && contributorListItems}
           
          </List>
        </Drawer>)};
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 1 }}>
          
         
          <AppRouter></AppRouter>
         
          </Container>
        </Box>
      </Box>
      </Router>
    </ThemeProvider>
  );
}

export default function Landing() {
  return <DashboardContent />;
}

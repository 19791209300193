import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import {setRole } from "../redux/actions";
const categorys = [
  {
    title: 'Candidate',
    value:'candidate',
    description: [
      'You can learn the specific skills  by our tools for remote work.It helps in key areas for coorpare job',
      
    ],
    buttonText: 'Sign up for Practice',
    buttonVariant: 'outlined',
  },
  {
    title: 'Contributer',
    value:'contributer',
    description: [
      'Anyone can contribute the questions and  to earn ETH coins.You can also review the others questions.',
      
    ],
    buttonText: 'Start Contributing',
    buttonVariant: 'outlined',
  },
  {
    title: 'Hiring',
    value:'hiring',
    description: [
      'Evaluate the candiate using AI based Technology to save your time for right person to your company.',
      ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  }
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];
const Content = (props) => {
 
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
 
  const gotoLoginPage = (role) => {
    console.log(props)
    props.setRole(role);
    loginWithRedirect()
  }
    return (
      <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          WeEvaluate
        </Typography>
        <Typography
          component="h4"
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
           Beta  Version
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
        We will provide AI base solution to evaluate candidate for employer and anyone can contribute question to earn ETH coins.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {categorys.map((category) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={category.title}
              xs={12}
              sm={category.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={category.title}
                  subheader={category.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={category.title === 'Hiring' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                 
                  <ul>
                    {category.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={category.buttonVariant}  onClick={() => gotoLoginPage(category.value)}>
                    {category.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
     
    </React.Fragment>
    );
  }
 
  export default connect(null,{setRole})(Content);


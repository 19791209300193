const commonJSutils = {
    compareRole: (dbRole, role) => {
        return dbRole === role
    },
    isEmpty: (arr) => {
        console.log(arr)
       if (!arr) return false
        return (!arr.length) ? false : true
    },

    isExisted: (arr, str) => {
        console.log(arr.length)
        console.log(arr.every(a => a === str),arr,str)
        if(arr.length>0)
        return arr ? arr.every(a => a === str) : false

        return false
       
    }

}


export default commonJSutils;  

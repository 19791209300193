import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { purple } from '@mui/material/colors';
import { Item,ColorButton } from '../components/Common'; 

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
 const testQuestions=[
  {question:'this is firstQuestion',option:["option1","option2","option3","option4"]},
  {question:'this is SecondQuestion',option:["option2","option2","option3","option4"]}
]
function Assignment (){
    const [progress, setProgress] = React.useState(0);
    const [assignmentTime, setAssignmentTime] = React.useState(0);
    const [questionCount, setQuestionCount] = React.useState(0);
    const [state, setState] = React.useState({
      showSubmit: false,
      showEndTest: false,
      apiMessage: "",
      error: null,
      questionProgess:0
    });
    const testTime=1
    const nextQuestion = (event) => {
      setQuestionCount(questionCount+1)
      if (questionCount===testQuestions.length-2)  setState({...state,showEndTest: true,})
    };
    const endTest = (event) => {
      setQuestionCount(questionCount+1)
      if(state.showEndTest) setState({...state,showSubmit:true,showEndTest: false,})
    };
    const submitTest = (event) => {
 
    };
    React.useEffect(() => {
        const timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 100 ? 1 : prevProgress + 2));
          setAssignmentTime()
        }, testTime*60*1000/50);
        return () => {
          clearInterval(timer);
        };
      }, []);
    return (
        <div className='developer-page'>
             <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
   
    name="radio-buttons-group"
  >
            <Grid container spacing={1} sx={{m:4}}>
            <Grid xs={9} spacing={1}>
        <Box >
          
          <Grid container spacing={1}>
          <Grid item xs={2}>
              <Item>Test Time</Item>
            </Grid>
            <Grid item xs={10}>
              <Item> <LinearProgressWithLabel value={progress} /></Item>
            </Grid>{ !state.showSubmit && (<>
            <Grid item xs={12}>
              <Item>{testQuestions[questionCount].question}</Item>
            </Grid>
            {testQuestions[questionCount].option.map((option) => (<>
            <Grid item xs={6}>
              <Item><FormControlLabel value={option} control={<Radio />} label={option} /></Item>
            </Grid>
            </>))}
            </>)}
            <Grid item xs={12}>
              <Item style={{height:'70px'}}>
              <Stack spacing={1} direction="row"  style={{float:"right"}}>
                {!state.showSubmit && !state.showEndTest && (<Button variant="contained"  onClick={nextQuestion} >Next</Button>)}
                {state.showEndTest && (<Button variant="contained"  onClick={endTest} >End Test</Button>)}
                {state.showSubmit && (<Button variant="contained"  onClick={submitTest} >Submit Test </Button>)}
                <ColorButton variant="contained">Need Help?</ColorButton>
                 
          </Stack>
    </Item>
            </Grid>
            <Grid item xs={9}>
              <Item> <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={questionCount/testQuestions.length*100} />
    </Box></Item>
            </Grid>
            <Grid item xs={3}>
              <Item>Questions :<b> {questionCount} / {testQuestions.length}</b></Item>
            </Grid>
          </Grid>
        </Box>
        </Grid>
        <Grid item xs={3}>
             
        </Grid>  
        </Grid>
        </RadioGroup>
        </div>
      );
}
  
export default Assignment;
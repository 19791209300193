import React, { useRef } from "react";
import { Container} from "reactstrap";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import WalletQuestionsDataTable from '../components/WalletQuestionsDataTable';
import ContributorQuestionsDataTable from '../components/ContributorQuestionsDataTable';
import FreeQuestionsDataTable from '../components/FreeQuestionsDataTable';

import Loading from "../components/Loading";
import { getConfig } from "../config";
import agent from '../services/agent';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Item,ColorButton } from '../components/Common'; 

export const QuestionsComponent = () => {
  const { user } = useAuth0();
  const ref = useRef(null);
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();

  const [state, setState] = React.useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const [question,setQuestion]= React.useState({question:{}})


    
  const [value, setValue] = React.useState('1');
  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
      const handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        console.log(event.target.name)
          
         // question[event.target.name]=event.target.value
        
          setQuestion({
            ...question,
            [name]: target.value,
          });
      };
    const handleQuestionChange = (event) => {
        const target = event.target;
        const name = target.name;
        console.log(event.target.name)
        setQuestion({
          ...question,
         question : ({
          ...question.question,
          [name]:target.value
         })
        });
      
        
         
      };
      

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await saveQuestion();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await saveQuestion();
  };

  const saveQuestion = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
      console.log(question);
      const res= await agent.Question.save(ref.current.value)
       } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

      return (
    <Container >
     <Box sx={{ flexGrow: 1 }}>
             <Grid container spacing={2}>
          <Grid item xs={6} >
        <Grid container spacing={.5}>
          <Grid item xs={6}>
          <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Question Type</InputLabel>
                <Select
                name="type"
                  labelId="question-form-select-label"
                  id="question-form-select"
                  value={question.type}
                  label="Question Type"
                  onChange={handleChange}
                >
                  <MenuItem value={'ProblemSolving'}>Problem solving</MenuItem>
                  <MenuItem value={10}>Time management</MenuItem>
                  <MenuItem value={20}>Critical Thinking</MenuItem>
                  <MenuItem value={30}>Creativity</MenuItem>
                  <MenuItem value={10}>Teamwork</MenuItem>
                  <MenuItem value={10}>Organization </MenuItem>
                  <MenuItem value={20}>Flexibility</MenuItem>
                  <MenuItem value={30}>Reading</MenuItem>
                  <MenuItem value={30}>Writing</MenuItem>
                  <MenuItem value={30}>Coding</MenuItem>
                </Select>
              </FormControl></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Difficult Level</InputLabel>
                <Select
                  name="level"
                  labelId="question-form-select-label"
                  id="question-form-select"
                  value={question.level}
                  label="Difficult Level"
                  onChange={handleChange}
                >
                  <MenuItem value={'easy'}>Easy</MenuItem>
                  <MenuItem value={'medium'}>Medium</MenuItem>
                  <MenuItem value={'hard'}>Hard</MenuItem>
                  
                </Select>
              </FormControl>
              </Item>
          </Grid>
          <Grid item xs={6}>
          <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Formate</InputLabel>
                <Select
                name="formate"
                  labelId="question-form-select-label"
                  id="question-form-select"
                  value={question.formate}
                  label="Formate"
                  onChange={handleChange}
                >
                  <MenuItem value={'Text'}>Text</MenuItem>
                  <MenuItem value={'Image'}>Image</MenuItem>
                  <MenuItem value={'Audio'}>Audio</MenuItem>
                  <MenuItem value={'Video'}>Video</MenuItem>
                  
                </Select>
              </FormControl>
              </Item>
          </Grid>
          <Grid item xs={6} style={{padding:'20px',paddingLeft:'15%'}}>
            <Button
      variant="contained"
      component="label"
    >
      Upload File
      <input
        type="file"
        hidden
        name="uploadFile"
      />
    </Button>
          </Grid>
          
          <Grid item xs={12}>
          <Item><TextareaAutosize
          aria-label="empty textarea"
          placeholder="Enter your question"
          minRows={8}
          name="question"
          style={{ width: '100%' }}
        
          onChange={handleQuestionChange}
        /></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><TextField fullWidth onChange={handleQuestionChange}  name="option1" id="outlined-basic" label="Option 1" variant="outlined" /></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><TextField fullWidth onChange={handleQuestionChange}  name="option2" id="outlined-basic" label="Option 2" variant="outlined" /></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><TextField fullWidth onChange={handleQuestionChange}  name="option3" id="outlined-basic" label="Option 3" variant="outlined" /></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><TextField fullWidth onChange={handleQuestionChange}  name="option4" id="outlined-basic" label="Option 4" variant="outlined" /></Item>
          </Grid>
          <Grid item xs={6}>
            <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Choose Answer</InputLabel>
                <Select
                  labelId="question-form-select-label"
                  id="question-form-select"
                  onChange={handleQuestionChange}  name="answer"
                  label="Choose Answer"
                 
                >
                  <MenuItem value={question.question.option1}>Option 1</MenuItem>
                  <MenuItem value={question.question.option2}>Option 2</MenuItem>
                  <MenuItem value={question.question.option3}>Option 3</MenuItem>
                  <MenuItem value={question.question.option4}>Option 4</MenuItem>
                  
                </Select>
              </FormControl></Item>
          </Grid>
          <Grid item xs={6}>
          <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Time</InputLabel>
                <Select
                  labelId="question-form-select-label"
                  id="question-form-select"
                  onChange={handleChange}  name="time"
                  label="Make It"
                 
                >
                  <MenuItem value={'Public'}>30 Sec</MenuItem>
                  <MenuItem value={'Self'}>1 Min</MenuItem>
                  <MenuItem value={'Contributor'}>1 Min 30 Sec</MenuItem>
                  
                </Select>
              </FormControl></Item>
          </Grid>
          <Grid item xs={6}>
            <Item><FormControl fullWidth>
                <InputLabel id="question-form-select-label">Make It</InputLabel>
                <Select
                  labelId="question-form-select-label"
                  id="question-form-select"
                  onChange={handleChange}  name="makeit"
                  label="Make It"
                 
                >
                  <MenuItem value={'Public'}>Public</MenuItem>
                  <MenuItem value={'Self'}>Self</MenuItem>
                  <MenuItem value={'Contributor'}>Sell</MenuItem>
                  
                </Select>
              </FormControl></Item>
          </Grid>
          <Grid item xs={6}>
         
          <Item><TextField fullWidth  id="outlined-basic" label="Cost" variant="outlined" /></Item>
          </Grid>
          <Grid item xs={12}>
            <Item><TextareaAutosize
          aria-label="empty textarea"
          placeholder="Enter the solution for this question for Selling/Free"
          minRows={8}
          style={{ width: '100%' }}
        /></Item>
          </Grid>
          <Grid item xs={12}>
          <Item style={{height:'55px'}}> <Stack spacing={1} direction="row"  style={{float:"right"}}>
              <ColorButton variant="contained">View Question</ColorButton>
                    <Button variant="contained"  onClick={() => saveQuestion()}>Save</Button>
                    <Button variant="contained">Cancel</Button>
                 
                     
              </Stack></Item>
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={6}>
          <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
          <Tab label="Your Wallet" value="1" />
          <Tab label="Contributors" value="2" />
          <Tab label="Free" value="3" />
        </TabList>
      </Box>
      <TabPanel value="1" style={{margin:0,padding:0}}><WalletQuestionsDataTable/></TabPanel>
      <TabPanel value="2" style={{margin:0,padding:0}}><ContributorQuestionsDataTable/></TabPanel>
      <TabPanel value="3" style={{margin:0,padding:0}}><FreeQuestionsDataTable/></TabPanel>
    </TabContext>
          
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default withAuthenticationRequired(QuestionsComponent, {
  onRedirecting: () => <Loading />,
});

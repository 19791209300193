import { SET_ROLE } from "../actionTypes";
var CryptoJS = require("crypto-js");

// Encrypt
const secretKey="secret key 123";
const ROLE_HIRING="hiring";
const ROLE_CONTRIBUTER="contributer";
const ROLE_CANDIDATE="candidate";
var ciphertext = CryptoJS.AES.encrypt('my message', secretKey).toString();

// Decrypt
var bytes  = CryptoJS.AES.decrypt(ciphertext, secretKey);
var originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log(originalText);
const getActualText = () => {

  if(!localStorage.getItem(window.btoa("selectedoption"))) return null

  var ciphertext = CryptoJS.HmacSHA1(ROLE_HIRING, secretKey).toString();
  if(ciphertext===localStorage.getItem(window.btoa("selectedoption"))) return ROLE_HIRING
  ciphertext = CryptoJS.HmacSHA1(ROLE_CONTRIBUTER, secretKey).toString();
  if(ciphertext===localStorage.getItem(window.btoa("selectedoption"))) return ROLE_CONTRIBUTER
  ciphertext = CryptoJS.HmacSHA1(ROLE_CANDIDATE, secretKey).toString();
  if(ciphertext===localStorage.getItem(window.btoa("selectedoption"))) return ROLE_CANDIDATE
  
  /*var bytes = CryptoJS.AES.decrypt(
    localStorage.getItem(window.btoa("selectedoption"),
    "secret key 123"
    )
  );

  var actualText = bytes.toString(CryptoJS.enc.Utf8);
  return actualText;*/
  return "wrongword"
};
const initialState = {
  allIds: [],
  byIds: {},
  role: getActualText(),
};

const todos = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE: {
      const { role } = action.payload;
      var ciphertext = CryptoJS.HmacSHA1(
        role,
        secretKey
      ).toString();
      localStorage.setItem(window.btoa("selectedoption"), ciphertext);
      return {
        ...state,
        role: role,
      };
    }
    default:
      return state;
  }
};
export default todos;
